<template>
    <div v-if="data" class="position-relative" v-on:click="redirectToSingleAd" style="cursor: pointer">
                    
        <b-button pill class="badge-active d-flex align-items-center" v-if="expires_date && data.approved" @click="showModal">
            <div>
                Izbriši 
            </div>
            <img class="ml-1" style="width: 20px; height: 20px;" :src="require('@/assets/svg/Circle_X.svg')"/>
        </b-button>
        <b-button pill class="badge-inactive d-flex align-items-center" v-else-if="data.approved" @click="approveC2C">
            <div>
                Aktiviraj 
            </div>
            <img class="ml-1" style="width: 20px; height: 20px;" :src="require('@/assets/svg/Circle_X.svg')"/>
        </b-button>
        <div class="card-content">
            <!-- <router-link :to="{ name: 'real-estate', params: {real_estate_id: data.id, realEstateTitle: data.title}}"> -->
            <!-- <div class="location">{{realEstate.location.toUpperCase()}}</div> -->
            <!-- </router-link> -->
            <div class="mt-auto d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-start flex-column">
                    <div class="mb-1 c2c-title-test">
                        {{data.title}}
                    </div>
                    <div class="c2c-unaproved" v-if="!data.approved">
                        NA ČAKANJU
                    </div>
                    <div class="c2c-active" v-else-if="expires_date">
                        AKTIVEN
                    </div>
                    <div class="c2c-inactive" v-else>
                        NEAKTIVEN
                    </div>
                    

                </div>
                <div class="ml-1 d-flex align-items-start flex-column">
                    <div class="mb-1 w-100 text-right c2c-expires">
                        {{date_posted}}
                    </div>
                    <div class="w-100 text-right c2c-price">
                        <div>
                            {{price}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-img v-if="data.main_photo" class="main-photo" :src="`/api/user/v1/image/medium_${data.main_photo}`"/>
        <ConfirmActionModal :modal-show="ModalShowConfirmAction" title="Potrdite izbris oglasa" v-on:confirm-action="deleteC2C" @hide="ModalShowConfirmAction=false" @close="ModalShowConfirmAction=false"/>

    </div>
</template>

<script>
    import {BImg, BButton} from 'bootstrap-vue'
    import ConfirmActionModal from '@/views/Components/ConfirmActionModal.vue'

    export default {
        components: {
            BImg,
            BButton,
            ConfirmActionModal
        },
        data() {
            return {
                ModalShowConfirmAction: false
            }
        },
        computed:{
            expires_date() {
                if (this.data && new Date(this.data.expires) > Date.now()) return true
                else return false
            },
            date_posted() {
                const date = new Date(this.data.date_posted)

                if (date) {
                    const dayjs_date =  this.dayjs(date)

                    if (dayjs_date) {
                        return dayjs_date.format('DD.MM.YYYY')
                    }
                }

                return null
            },
            price() {
                if (this.data && this.data.price) return `${ (this.data.price / 100).toFixed(2)  }€ `
                else return null
            }
        },
        methods:{
            showModal(evnt) {
                evnt.stopPropagation()
                this.ModalShowConfirmAction = true
            },
            async deleteC2C() {

                try {
                    await this.$http.delete(`/api/user/v1/c2c/user_c2c/${  this.data.id}`)

                    this.$printSuccess('Oglas je bil uspešno izbrisan')

                    this.$emit('reloadAds')

                } catch (error) {
                    this.$printError('Prislo je do napake pri nalaganju oglasa!')
                }
            },
            async approveC2C(evnt) {
                evnt.stopPropagation()
                try {
                    await this.$http.post(`/api/user/v1/c2c/user_c2c/${  this.data.id}`)

                    this.data.approved = true
                    this.data.expires = new Date(new Date().setDate(new Date().getDate() + 30))
                    this.data.approved_at = new Date()
                    this.data.last_edit_time = new Date()

                    this.$printSuccess('Oglas je bil uspešno potrjen')

                } catch (error) {
                    this.$printError('Prislo je do napake pri nalaganju oglasa!')
                }

            },
            redirectToSingleAd() {
                this.$scrollToTop()
                this.$router.push({ name: 'my-single-ad', params: {ad_id: this.data.id}})
            }
        },
        mounted() {
        },
        props:{
            data:{
                type: Object
            }
        }
    }
</script>

<style scoped>
.badge-active {
  border-radius: 20px !important;
  position: absolute;
  background-color: #72A5D8;
  color: white;
  top: 15px;
  right: 15px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  padding-right: 1rem;
  padding-left: 1rem;
  padding: 5px;
  border: none !important;
  z-index: 2;
}

.badge-active:active, .badge-active:hover, .badge-active:focus {
  color: black !important;
  box-shadow: none !important;
}

.badge-inactive {
  border-radius: 20px !important;
  position: absolute;
  border: none !important;
  background: #1AE49B !important;
  color: white;
  top: 15px;
  right: 15px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  padding-right: 1rem;
  padding-left: 1rem;
  padding: 5px;
  z-index: 2;
}

.badge-inactive:active, .badge-inactive:hover, .badge-inactive:focus {
  color: black !important;
  box-shadow: none !important;
}
.over-image{
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  position: absolute;
  top: 2.4rem;
  left: 0;
  width: 100%;
  padding: 1.8rem;
  z-index: 1;
}
.c2c-title {
  font-weight: 400;
  line-height: 17px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
a {
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  color: #72A5D8;
}
.card-content{
  background: rgba(255, 255, 255, 0.88);
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  padding: 0.8rem 1.8rem;
}
.main-photo {
  width: 100%;
  height: 300px !important;
  min-height: 300px !important;
  object-fit: cover;
}

.c2c-price{
    font-weight: bold;
    font-size: 20px;
}
.c2c-expires{
    color: #72A5D8;
    font-size: 17px;
}

.c2c-active{
    color: #72A5D8;
    font-weight: bold;
    font-size: 17px;

}

.c2c-unaproved{
    color: #FFAB2E;
    font-weight: bold;
    font-size: 17px;
}

.c2c-inactive{
    color: #FF2E2E;
    font-weight: bold;
    font-size: 17px;
}
</style>