<template>
    <div >
        <div class="w-100 d-flex justify-content-end">
            <b-button class="d-flex align-items-center objava-oglasa" pill @click="$router.push({name: 'add-ad'}); $scrollToTop()">
                <div>OBJAVA OGLASA</div>
                <img class="ml-1" :src="require('@/assets/svg/circle-plus.svg')"/>
            </b-button>
        </div>
       
        <div class="mt-5" style="position: relative;">
            <div class="light-background" />
            <div class="main-text">
                VSI MOJI OGLASI
            </div>
        </div>
       
        <div v-if="data && data.length > 0">
            <div class="row" >
                <div class="col-lg-4 col-md-6 col-12 mt-3" v-for=" item of data" :key="'oglas_' + item.id">
                    <my-classified-ad-component :data="item" v-on:reloadAds="loadData" />
                </div>
            </div>
            <div class="text-center">
                <pagination v-if="pagination" v-model="pagination" class="mt-4"/>
            </div>
        </div>
        <div v-else class="d-flex justify-content-center w-100 mt-5 no-ads">
            Objavili niste še nobenega oglasa
        </div>
       
    </div>
</template>

<script>
    import MyClassifiedAdComponent from './Components/MyClassifiedAdComponent.vue'
    import Pagination from '@/views/Components/Pagination'
    import { BButton } from 'bootstrap-vue'

    export default {
        components: { MyClassifiedAdComponent, Pagination, BButton },
        data() {
            return {
                data: null,
                pagination: {
                    current_page: 1,
                    next_page: 1,
                    per_page: 12,
                    prev_page: 0,
                    total_items: 0,
                    total_pages: 0
                },
                loading: false
            }
        },
        methods:{
            async loadData() {
                try {
                    this.loading = true
                    const res = await this.$http.get(`/api/user/v1/c2c/user_c2c?page=${this.pagination.current_page}&perPage=${this.pagination.per_page}`)
                    this.data = res.data.classified_ads
                    this.pagination = res.data.pagination
                    this.loading = false
                } catch (error) {
                    this.$printError('Prišlo je do napake pri nalaganju oglasov')
                }
                
            }
        },
        mounted() {
            this.loadData()
        },
        watch: {
            'pagination.current_page'() {
                if (!this.loading) this.loadData()
            }
        }
    }
</script>

<style scoped>
.no-ads{
    font-size: 25px;

}

.objava-oglasa:hover, .objava-oglasa:active, .objava-oglasa:focus{
    box-shadow: none !important;
}
 .light-background{
        position: absolute;
        top: -15px;
        height: 150px;
        width: 9999px;
        margin-left: -2000px !important;
        margin-right: -2000px !important;
        background: linear-gradient(#EAF3F9, white) !important;
        z-index: -1;
    }
    .main-text{
        font-size: 19px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.3);
    }
</style>