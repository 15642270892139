<template>
    <BModal v-model="isModalVisible" centered ref="ConfirmActionModal" hide-header hide-footer @hide="$emit('close')" @close="$emit('close')">
        <div class="w-100 d-flex justify-content-center" >
            <h3 style="font-weight: bold;">{{title}}</h3>
        </div>
        <div class="d-flex justify-content-center mt-2">
            <rounded-button font-size="18px" padding="0.5rem 4rem" variant="danger" class="mx-1" @click="$emit('close')">Prekliči</rounded-button>
            <rounded-button font-size="18px" padding="0.5rem 4rem" class="mr-1" @click="confirm">Potrdi</rounded-button>
        </div>
    </BModal>
</template>


<script>

    import { BModal} from 'bootstrap-vue'
    import RoundedButton from '@/views/Components/RoundedButton'

    export default {

        components:{
            BModal,
            RoundedButton
        },
        props:{
            title: {
                type: String
            },
            modalShow:{
                type: Boolean,
                default: false
            }
        },
        methods:{
            confirm() {
                this.$emit('confirm-action')
                this.$emit('close')
            }
        },
        data() {
            return {
                isModalVisible: false
            }
        },
        watch:{
            'modalShow'(val) {
                this.isModalVisible = val
            }
        }
    }
</script>